import PropertyMaintenance from "../assets/img/services/Property-Maintenance/image6.png";
import HardscapeConstruction from "../assets/img/services/Hardscape/2.jpg";
import Snow from "../assets/img/services/4.png";
import TreeServices from "../assets/img/services/Tree-Services/4.jpg";
import WasteManagementServices from "../assets/img/services/Waste-Management-Services/1.jpg";
import IrrigationServices from "../assets/img/services/7.png";
import SoftscapeConstruction from "../assets/img/services/8.png";
import Fertilization from "../assets/img/services/10.jpg";

import project1 from "../assets/img/projects/deck/8.png";
import project2 from "../assets/img/projects/pergola/5.png";
import project3 from "../assets/img/projects/backyard/1.jpg";
import project4 from "../assets/img/projects/SOD/after2.jpg";
import project5 from "../assets/img/projects/yard-transformation/1.jpg";
// import project6 from "../assets/img/projects/Stone-Front-Steps/placeholder.png";

import profile1 from "../assets/img/crew/Jon-Agg.png";
import profile2 from "../assets/img/crew/Harry-Harutunian.jpg";
import profile3 from "../assets/img/crew/dummy.png";
import profile4 from "../assets/img/crew/Richard-Eaton.png";
import profile6 from "../assets/img/crew/Keshia-Garcia.png";
// import dummyprofile from "../assets/img/crew/dummy.png";
import profile8 from "../assets/img/crew/Alicia-Tyo.jpg";

import profile9 from "../assets/img/crew/Carol-Fulford.jpg";
import dummyprofile from "../assets/img/crew/dummy.png";
import profile10 from "../assets/img/crew/Peter-Gayes.jpg";
import profile11 from "../assets/img/crew/adam-tyos.jpg";
import profile12 from "../assets/img/crew/Brendan-Kowal.jpg";
import profile13 from "../assets/img/crew/Kyle-Bartkow.jpg";

export const MenuList = [
  {
    title: "Home",
    key: "home",
    link: "/",
  },
  {
    title: "Services",
    key: "services",
    link: "/pristine-services",
  },
  {
    title: "About Us",
    key: "aboutus",
    link: "/about-us",
  },
  {
    title: "Portfolio",
    key: "portfolio",
    link: "/featured-projects",
  },
  {
    title: "Careers",
    key: "careers",
    link: "/careers",
  },
  {
    title: "Request a quote",
    key: "requestquote",
    link: "/request-qoute",
  },
  {
    title: "Contact Us",
    key: "contactus",
    link: "/contact-us",
  },
];

export const ServiceimageList = [
  {
    title: "Property Maintenance",
    image: PropertyMaintenance,
    eventKey: "service1",
  },
  {
    title: "Snow & Ice Management",
    image: Snow,
    eventKey: "service2",
  },
  {
    title: "Hardscape Construction",
    image: HardscapeConstruction,
    eventKey: "service3",
  },
  {
    title: "Softscape Construction",
    image: SoftscapeConstruction,
    eventKey: "service4",
  },
  {
    title: "Tree Services",
    image: TreeServices,
    eventKey: "service5",
  },
  // {
  // 	title: 'Fence & Deck Services',
  // 	image: Fence,
  // 	eventKey: "service6",
  // },
  {
    title: "Irrigation Services",
    image: IrrigationServices,
    eventKey: "service7",
  },
  {
    title: "Fertilization and Weed Control Services",
    image: Fertilization,
    eventKey: "service8",
  },
  {
    title: "Waste Management Services",
    image: WasteManagementServices,
    eventKey: "service9",
  },
];

export const projectList = [
  {
    title: "Decks and Fencing",
    image: project1,
    link: "/featured-projects/deck-fence",
  },
  {
    title: "Pergola",
    image: project2,
    link: "/featured-projects/pergola",
  },
  {
    title: "Reclaiming a Backyard",
    image: project3,
    link: "/featured-projects/reclaiming-backyard",
  },
  {
    title: "SOD",
    image: project4,
    link: "/featured-projects/Sod",
  },
  {
    title: "Yard Transformation",
    image: project5,
    link: "/featured-projects/yard-transformation",
  },
  // {
  //   title: "Stone Front Steps",
  //   image: project6,
  //   link: "/featured-projects/stone-front-steps",
  // },
  // {
  // 	title: 'Tree Maintenance',
  // 	image: project5,
  // 	link:'/featured-projects/tree-maintenance',
  // },
];

export const TestimonailsList = [
  {
    title: "“Lots of gardening and yard work was done on the May long weekend. Thank you to PPM in Brighton for delivering our mulch and garden soil. Price, service and delivery was fantastic!”",
    name: "Claire Kehoe",
    designation: " – June 2024",
  },
  {
    title: "“Excellent customer service these guys know their stuff!! Delivery was right on time and very friendly. Totally recommend this company.”",
    name: "Darrell and Vivian Peters",
    designation: " – June 2024",
  },
  {
    title:
      "“We have been with the “Pristine Boys” – now men since they began their business years ago.  Service has always been impressive, professional and personalized.  Responses are timely and requests dealt with in the suggested time frame. A great company to work with!”",
    name: "Nicola Phillips",
    designation: "Principal/Owner, Montessori Learning Centre of Pickering",
  },
  {
    title:
      "“Thank you for the awesome service and professional installation of the fence at our home in Brighton. Excellent service and professional workmanship this company manages to provide both – and in a timely manner. Thanks again”",
    name: "P.G. Rose",
    designation: " - June 2022",
  },
  {
    title:
      "“I really needed some help. This company came thru for me and not only plowed our driveway but salted it as well. They did an exceptional job. Professional courteous service. Reasonably priced. Highly recommended!”",
    name: "Cindy Klotz",
    designation: " – Feb 2022",
  },
  {
    title:
      "“My experience with PPM (Jon and crew) has been great, did landscaping, regrading and new sod in entire backyard. Looks great and we enjoyed using our new space all summer. Crews are polite and efficient and even came by in the weeks after to check on the new sods progress ; they even replaced a few small areas that didn't catch well without being asked! Very Impressed and appreciative. Will be reaching out to them to arrange snow removal service this coming winter.”",
    name: "Bill M",
    designation: " – Jan 2022",
  },
  {
    title:
      "“Staff is very friendly, responsive and the work they did was great. I would highly recommend their service.”",
    name: "John Kalimeris",
    designation: " – Jan 2022",
  },
];

export const crewList = [
  {
    name: "Jon Agg",
    subheading: "Owner",
    details:
      "Jon started PPM in November 2005 with 1 condo corporation, a real estate office, a Montessori School and about 20 houses that he had serviced since the age of 12.  Having a passion for property maintenance, he initially focused on this and snow and ice control as the main components of the business.  Doubling in size every year and realizing the potential in all of the other divisions that PPM is now involved with caused Jon to look for the best of the best to partner with and give them a division to run. Collectively growing the company into one of the best and largest companies in its industry.  Jon is married to Suzanne who is a Guidance Counsellor in Scarborough.  They live in the Centennial area and enjoy their beautiful backyard landscaped by PPM!",
    image: profile1,
  },
  {
    name: "Harry Harutunian",
    subheading: "Operations Manager- Logistics",
    details:
      "Harry comes to Pristine with more than 19 years of experience in landscape maintenance operations, snow removal, and golf course maintenance. Harry received a diploma from Centennial College in Productions and Operations and ran his own business for several years. Harry has served as a Vice President of Operations and has a plethora of experience in managing people.",
    image: profile2,
  },
  {
    name: "David McLaughlin",
    subheading: "Operations Manager- Administration",
    details:
      "David came to Pristine in a unique way! He sort of fell into the industry and hasn’t looked back since.  Even though he might have been on different career path he has always had interest and seated love for landscaping and property maintenance.  His first “job” was that of cutting grass, pruning shrubs, and weeding gardens for neighbours in the beautiful Muskoka.  David spent 11 years working in the forensic engineering industry who clients were specifically insurance companies which understandability gives him a strong background in the liability issues that we face.  He has a very strong background in sales and project management which he has been able to adapt very quickly to our ever-growing company and has made him a great asset in out team.   “Learn something new everyday, if not you are dead”  ",
    image: profile3,
  },
  {
    name: "Richard Eaton",
    subheading: "Soil & Plant Health Specialist",
    details:
      "Richard has decades of experience in the green industry. He has worked as an arborist and landscape designer in both eastern Canada and the metropolitan areas around New York City and Washington DC. His soils related training through his Bachelor of Science degree makes him a good resource in building and maintaining sustainable landscapes. ",
    image: profile4,
  },
  {
    name: "Keshia Garcia",
    subheading: "Accounts Payable",
    details:
      "Ten years ago Keshia joined PPM as an administrative assistant. She currently takes care of the business expenses and manages the accounts payable department. Keshia majored in Accounting Business Administration and brings to the table 15 years in the management, financial and customer service field. Outside of PPM, Keshia successfully ran two small businesses within the event management and baking industry. She enjoys an active lifestyle with her partner and three kids, travelling, learning and always looking to self improve.",
    image: profile6,
  },
  {
    name: "Adam Tyo",
    subheading: "Eastern Ontario Manager",
    details:
      "Adam Tyo started with PPM in 2007 working as a crew member with Jon as his crew leader. He was 17 at the time, and after 1 year, Jon made the decision to promote another individual on the crew who was a bit older, which caused Adam (Tyo is what most people call him) to look at a career change. After a big snow storm, Jon called Tyo begging for him to help plow, and thankfully he did. He worked two jobs for a period of time, and then realized that he actually did miss the wonderful world of Snow and Ice Control, as well as summer services. He quit working at the surveying company he originally left PPM for, and has never looked back. As the third employee of PPM, he has consistently grown over the years, in both his knowledge of the industry, and as a person, and now holds the position of General Manager of the entire company, while also running the day to day operations of the fast-growing Eastern Ontario Division.  Tyo lives in Stirling Ontario with his wife Alicia, and their two beautiful children Carter and Cayden.",
    image: profile11,
  },
  {
    name: "Alicia Tyo",
    subheading: "Office Manager",
    details:
      "Alicia Tyo started with PPM in some ways the day her husband did but only received a paycheque as of 2012. Originally hired to help fill in for another team member on Maternity Leave, Alicia quickly showed what 5 years of history riding shotgun in the winter and helping in the summer was worth. Her inherent knowledge of the day-to-day operations of the company were a huge blessing and she quickly grew from the role as an Office Administrator to Office Manager/ the go to person for anything PPM related when you don’t know who to ask. She not only worked in the office but when help was needed in the field she was there. As PPM grew from an office above a flower shop to our current 10,000 sq foot plus facility, she was instrumental in the entire operation and growth of the administration side of the company. When not dealing with contracts, invoicing and administrative tasks, Alicia enjoys spending time with her family.",
    image: profile8,
  },
  {
    name: "Carol Fulford",
    subheading: "Contractor Manager",
    details: "Carol Fulford also known as the Jack of all trades, master of none has been working with PPM since November 2018 as our Contractor Manager. Carol has been in the landscaping industry for over 30 years! Anything you need, she is our go-to person! When she is not working, she enjoys spending time at her cottage with all of her family and friends.",
    image: profile9,
  },
  {
    name: "Kaitlyn Brown",
    subheading: "HR Administrator/ Marketing",
    details: "Kaitlyn Brown started with PPM in January of 2022. Hired as an Office Assistant, her role quickly changed to the HR Administrator along with all of PPMs marketing/advertising. Kaitlyn came to us with 7 years of experience in an Accounts Payable role and had completed multiple Human Resources classes through Durham College which gave her the opportunity to take on our HR Administrator role.",
    image: dummyprofile,
  },
  {
    name: "Peter-Gaye Lambert",
    subheading: "Controller",
    details: "Peter-Gaye Lambert began her journey with PPM in 2019 as a bookkeeper, involved in record-keeping and developed a strong foundation in our financial operations. Over the years, she has taken on increasing responsibilities and now proud to serve as the controller.",
    image: profile10,
  },
  {
    name: "Michael Small",
    subheading: "Softscape Construction Manager",
    details: "",
    image: dummyprofile,
  },
  {
    name: "Adam Eisler",
    subheading: "Hardscape Construction Manager",
    details: "",
    image: dummyprofile,
  },
  {
    name: "James King",
    subheading: "Fence & Deck Services Manager",
    details: "",
    image: dummyprofile,
  },
  {
    name: "Brendan Kowal",
    subheading: "Fertilization & Weed Control Manager",
    details:
      "Meet Brendan! Brendan manages our Turf Care Division. He has been fertilizing lawns for 20 years now and has held his pesticide license for 15+ years. Brendan started with PPM in 2019 and has been a valuable member ever since.",
    image: profile12,
  },
  {
    name: "Kyle Bartkow",
    subheading: "Facilities Management Supervisor",
    details:
      "Kyle started with us in 2011 as a Crew Leader in our GTA Division. In 2020 he was promoted to Supervisor of our Hydro One department. Kyle has completed many certifications such as; WHMIS, first aid, black bear awareness, white water safety and has also completed the Ranger Program with The Ministry of Natural Resources.",
    image: profile13,
  },
  {
    name: "Jamielee Burlie",
    subheading: "Office Assistant",
    details: "",
    image: dummyprofile,
  },
  {
    name: "Brandon Kendrick",
    subheading: "Kawarthas Manager",
    details: "",
    image: dummyprofile,
  },
];

export interface jobListItem {
  jobId: number;
  jobtitle: string;
  jobDescription?: any;
  jobType?: string;
}

export const jobList: jobListItem[] = [
  {
    jobId: 1,
    jobtitle: "Arborist: Ajax",
    jobDescription: `<p><span style="font-size:24px">Arborist required for growing tree care division in established landscaping company. </span></p>
		<p><span style="font-size:24px">PPM arborists are a tight group of professionals that are committed to high quality work and nurturing a team environment.  We work hard at maintaining work/life balance for our members and inspiring them to engage in a wide range of arboricultural activities.  We prune and remove trees. We balance off our removals by planting substantial numbers of large caliper trees and even engage in more detailed landscape installation projects.  Our plant health care department has annual programs of feeding trees, amending soils and protecting trees from de-icing salts.  We also do complex soil remediation projects with our air spade.
		</span></p>
		<p><span style="font-size:24px">We are looking for people to join our team as we grow.  We are interested in experienced arborists but also looking for passionate people who might want to enter the profession and be trained.  PPM offers year round employment and a great work environment.  Send us your resume and lets talk.
		</span></p>

		<ul>
			<li><span style="font-size:24px">Competencies in safety awareness; chainsaw operation; and basic climbing skills are a necessity. </span></li>
			<li><span style="font-size:24px">G license is an asset. </span></li>
			<li><span style="font-size:24px">DZ License and above is a bonus. </span></li>
			<li><span style="font-size:24px">Pristine Property Maintenance provides year-round employment.</span></li>
			<li><span style="font-size:24px">Great team atmosphere with ongoing training provided. </span></li>
			<li><span style="font-size:24px">Competitive wage will depend on experience.</span></li>
		</ul>
		`,
    jobType: "Full-time, Permanent",
  },
  {
    jobId: 2,
    jobtitle: "Small Engine Mechanic: Ajax ",
    jobDescription: `<p><span style="font-size:24px">Pristine Property Maintenance is looking for an experienced Small Engine Mechanic.</span></p>

		<ul>
			<li><span style="font-size:24px">Experience with Blowers, Trimmers, Lawn Mowers is a MUST.&nbsp;</span></li>
			<li><span style="font-size:24px">Experience with Toro Grandstands is an asset. G Licence is an asset.</span></li>
			<li><span style="font-size:24px">Competitive wage will depend on experience.</span></li>
			<li><span style="font-size:24px">The right candidate will be willing to work extra hours as needed.</span></li>
		</ul>
		`,
    jobType: "Full-time, Permanent",
  },
  {
    jobId: 3,
    jobtitle: "Crew Member: Ajax",
    jobDescription: `<ul>
		<li><span style="font-size:24px">Driver&rsquo;s license is an ASSET for crew members.</span></li>
		<li><span style="font-size:24px">You must be familiar with all equipment related to property maintenance such as push mowers, line trimmers, hedge clippers, backpack blowers, gardening tools and larger lawn mowing machines such as the Toro Grandstand would be an asset. &nbsp;</span></li>
		<li><span style="font-size:24px">Competitive wage will depend on experience.</span></li>
	</ul>
	`,
    jobType: "Full-time, Permanent, Seasonal",
  },
  {
    jobId: 4,
    jobtitle: "Crew Leader: Ajax",
    jobDescription: `<ul>
		<li><span style="font-size:24px">Driver&rsquo;s license is a MUST for crew leaders.</span></li>
		<li><span style="font-size:24px">You must be familiar with all equipment related to property maintenance such as push mowers, line trimmers, hedge clippers, backpack blowers, gardening tools and larger lawn mowing machines such as the Toro Grandstand would be an asset. &nbsp;</span></li>
		<li><span style="font-size:24px">Competitive wage will depend on experience.</span></li>
	</ul>
	`,
    jobType: "Full-time, Permanent, Seasonal",
  },
  {
    jobId: 5,
    jobtitle: "Construction Crew/Foreman: Ajax",
    jobDescription: `<p><span style="font-size:24px">Construction Crew/Foreman openings for the landscape construction division of Pristine Property Maintenance. Work will involve a variety of different tasks day to day.</span></p>

		<ul>
			<li><span style="font-size:24px">Heavy lifting and long hours should be expected.</span></li>
			<li><span style="font-size:24px">Weekend work is occasionally available but will not be a requirement of the position.</span></li>
			<li><span style="font-size:24px">One year experience is preferred.</span></li>
			<li><span style="font-size:24px">Valid drivers license with a clean drivers abstract is REQUIRED.</span></li>
			<li><span style="font-size:24px">Competitive wage will depend on experience.</span></li>
			<li><span style="font-size:24px">Construction Crew/Foreman openings for the landscape construction division of Pristine Property Maintenance.</span></li>
			<li><span style="font-size:24px">Work will involve a variety of different tasks day to day.</span></li>
			<li><span style="font-size:24px">Heavy lifting and long hours should be expected.</span></li>
			<li><span style="font-size:24px">Weekend work is occasionally available but will not be a requirement of the position.</span></li>
			<li><span style="font-size:24px">One year experience is preferred.</span></li>
			<li><span style="font-size:24px">Valid drivers license with a clean drivers abstract is REQUIRED.</span></li>
			<li><span style="font-size:24px">Competitive wage will depend on experience</span></li>
		</ul>
		
		`,
    jobType: "Full-time, Permanent",
  },
  // {
  //   jobId: 6,
  //   jobtitle: "Irrigation Crew Member: Ajax ",
  //   jobDescription: `<p><span style="font-size:24px">Pristine Property Maintenance is looking to hire Irrigation Crew Members.</span></p>

  // 	<p><span style="font-size:24px">Experience is an&nbsp;ASSET&nbsp;but willing to train if the right candidate is interested in learning.</span></p>

  // 	<ul>
  // 		<li><span style="font-size:24px">Drivers license in good standing is a&nbsp;MUST.&nbsp;</span></li>
  // 		<li><span style="font-size:24px">Hours vary depending on work.</span></li>
  // 		<li><span style="font-size:24px">Evening and weekend work may be required.</span></li>
  // 		<li><span style="font-size:24px">There will be a probation period for us to determine fit.</span></li>
  // 		<li><span style="font-size:24px">Competitive wage will depend on experience.</span></li>
  // 	</ul>

  // 	<p><strong><span style="font-size:18px">Please attach cover letter to your application. Why would you be a good fit?</span></strong></p>
  // 	`,
  //   jobType: "Full-time",
  // },
  {
    jobId: 6,
    jobtitle: "Irrigation Assistant: Ajax ",
    jobDescription: `<p><span style="font-size:24px">Pristine Property Maintenance Ltd is looking to hire an Irrigation Assistant. This position requires you to
    assist in operating, maintaining and repairing any existing or new install irrigation systems. Must be
    familiar with opening and closing of irrigation systems. 1+ years experience is preferred. Drivers license
    in good standing is a must. Wages will depend on experience.</span></p>

		<p><span style="font-size:24px">Skills and abilities you need:</span></p>
		
		<ul>
			<li><span style="font-size:24px">Hard Working</span></li>
			<li><span style="font-size:24px">Motivated</span></li>
			<li><span style="font-size:24px">Problem Solving Skills</span></li>
			<li><span style="font-size:24px">Must be willing to work independently and as a team</span></li>
			<li><span style="font-size:24px">Willing to learn</span></li>
		</ul>
		
		<p><strong><span style="font-size:18px">Please attach cover letter to your application. Why would you be a good fit?</span></strong></p>
		`,
    jobType: "",
  },
];




export const Locations: any = {
	1: 'Toronto GTA',
	2: 'Kawarthas – Supply Depot',
	3: 'Eastern Ontario Division',
	4: 'Near North Division',
	5: 'Capital Division',
  6: 'Brighton – Supply Depot',
}